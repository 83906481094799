<template>
  <div v-loading="loading" class="sys-role-component">
    <div class="button-line">
      <span class="title-span"> 权限清单 </span>
      <span>
        <el-button @click="goBackHandler">放弃更改</el-button>
        <el-button type="primary" @click="savePermission()">
          {{ $t("commons.save") }}
        </el-button>
      </span>
    </div>
    <el-row class="permissionContent">
      <el-collapse v-model="activeNames">
        <!-- 单个顶级权限结构 -->
        <el-collapse-item
          class="ruge-label-single"
          v-for="node in treeData"
          :key="node.id"
          :name="node.id"
        >
          <template slot="title">
            <!-- 第一层 -->
            <el-checkbox
              class="ruge-label-t"
              :indeterminate="node.indeterminate"
              v-model="node.check"
              :key="node.id"
              @change="renderCheck(node)"
              >{{ node.label }}</el-checkbox
            >
          </template>
          <!-- 单个二级权限结构 -->
          <div
            class="ruge-label-content"
            v-for="node_s1 in node.children"
            :key="node_s1.id"
          >
            <!-- 第二层 -->
            <div class="ruge-label-content-s1">
              <el-checkbox
                class="ruge-label-btn"
                :indeterminate="node_s1.indeterminate"
                :key="node_s1.id"
                v-model="node_s1.check"
                @change="renderCheck(node_s1)"
                >{{ node_s1.label }}</el-checkbox
              >
            </div>
            <!-- 第三层 -->
            <div class="ruge-label-content-s2">
              <el-checkbox
                class="ruge-label-btn-s"
                v-for="node_s2 in node_s1.children"
                :label="node_s2.label"
                v-model="node_s2.check"
                :key="node_s2.id"
                @change="renderCheck(node_s2)"
                >{{ node_s2.label }}</el-checkbox
              >
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-row>
  </div>
</template>

<script>
let manager = require("@/api/ruge/security/permission");
import { permissionStructTree } from "@/utils/tree";
export default {
  data() {
    return {
      loading: false,
      treeData: [],
      activeNames: [],
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    roleId() {
      return this.currentRow.roleId;
    },
  },
  watch: {
    roleId() {
      this.getAllPermission();
    },
  },
  methods: {
    goBackHandler() {
      this.$router.go(-1);
    },
    savePermission() {
      let submitPermission = this.getCheckValue();

      this.loading = true;
      manager
        ._sys_grantPermission({
          roleId: this.currentRow.roleId,
          operations: submitPermission,
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
          //this.checkedPermission();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkedPermission() {
      this.loading = true;
      manager
        ._sys_getPermissionByRoleId({ roleId: this.currentRow.roleId })
        .then((resp) => {
          let checks = { length: 0 };
          resp.map((r) => {
            if (r.operationId) {
              checks[r.operationId] = true;
            }
          });
          if (resp.length > 0) {
            this.viewCheckedPermission(checks);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewCheckedPermission(checks) {
      for (let i = 0, rootNode = this.treeData; i < rootNode.length; i++) {
        let firstNode = rootNode[i].children;
        if (firstNode) {
          for (let j = 0; j < firstNode.length; j++) {
            let lastNode = firstNode[j].children;
            if (lastNode) {
              for (let k = 0; k < lastNode.length; k++) {
                if (checks[lastNode[k].id]) {
                  lastNode[k].check = true;
                }
                if (k == lastNode.length - 1) {
                  //最后的一个节点再处理
                  this.parentCheck(lastNode[k]);
                }
              }
            }
          }
        }
      }
    },
    getAllPermission() {
      this.loading = true;
      manager
        .getCurrentAllPermission({
          tenantId: this.currentRow.tenantId,
          roleId: this.currentRow.roleId,
        })
        .then((resp) => {
          this.treeData = permissionStructTree(resp, this);
          this.setActiveTabs(this.treeData);
          this.checkedPermission();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setActiveTabs(datas) {
      this.activeNames = datas.map((item) => item.id);
    },
    renderCheck(node) {
      node.indeterminate = false;
      //处理父集
      this.parentCheck(node);
      //处理子集
      this.childrenCheck(node);
    },
    childrenCheck(node) {
      let childrenNode = node.children;
      if (childrenNode) {
        for (let i = 0; i < childrenNode.length; i++) {
          childrenNode[i].check = node.check;
          childrenNode[i].indeterminate = false;
          this.childrenCheck(childrenNode[i]);
        }
      }
    },
    parentCheck(node) {
      //找到父节点
      let parentNode = this.findParentNode(node);
      if (parentNode) {
        let checkCount = 0;
        let indeterminate = 0;
        let childrenNode = parentNode.children;
        if (childrenNode) {
          for (let i = 0; i < childrenNode.length; i++) {
            if (childrenNode[i].check) {
              checkCount += 1;
            }
            if (childrenNode[i].indeterminate) {
              indeterminate += 1;
            }
          }

          if (checkCount + indeterminate > 0) {
            if (checkCount == childrenNode.length) {
              parentNode.check = true;
              parentNode.indeterminate = false;
            } else {
              parentNode.check = false;
              parentNode.indeterminate = true;
            }
          } else {
            parentNode.check = false;
            parentNode.indeterminate = false;
          }
        }

        //一直找到根
        this.parentCheck(parentNode);
      }
    },
    findParentNode(node) {
      for (let i = 0, rootNode = this.treeData; i < rootNode.length; i++) {
        let firstNode = rootNode[i].children;
        if (firstNode) {
          for (let j = 0; j < firstNode.length; j++) {
            if (firstNode[j].id == node.id) {
              return rootNode[i];
            }
            let lastNode = firstNode[j].children;
            if (lastNode) {
              for (let k = 0; k < lastNode.length; k++) {
                if (lastNode[k].id == node.id) {
                  return firstNode[j];
                }
              }
            }
          }
        }
      }
      return null;
    },
    getCheckValue() {
      let operationIds = [];
      for (let i = 0, rootNode = this.treeData; i < rootNode.length; i++) {
        let firstNode = rootNode[i].children;
        if (firstNode) {
          for (let j = 0; j < firstNode.length; j++) {
            let lastNode = firstNode[j].children;
            if (lastNode) {
              for (let k = 0; k < lastNode.length; k++) {
                if (lastNode[k].check) {
                  operationIds.push({ operationId: lastNode[k].id });
                }
              }
            }
          }
        }
      }
      return operationIds;
    },
  },
  created() {
    this.getAllPermission();
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$label: #f4f4f4;
$borderColor: #e8e8e8;
.sys-role-component {
  .button-line {
    padding-bottom: 10px;
    text-align: right;
    border-bottom: 1px solid #f1f4f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-span {
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #252d3d;
    }
  }
}
.permissionContent {
  border: 1px solid $borderColor;
  border-bottom: none;
  margin-top: 10px;

  .permissionTitle {
    height: 40px;
    line-height: 40px;
    background: $label;
    padding: 0px 20px;
  }
  .ruge-label-single {
    .ruge-label-t {
      margin-left: 20px;
    }
    .ruge-label-btn,
    .ruge-label-btn-s {
      float: left;
      padding: 12px 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .ruge-label-btn {
      margin: 0px;
      background: $label;
      width: 180px;
      border-right: 1px solid $borderColor;
    }
    .ruge-label-content {
      display: table;
      overflow: hidden;
      border-top: 1px solid $borderColor;
      .ruge-label-content-s1 {
        display: table-cell;
        background: #f4f4f4;
        vertical-align: middle;
      }
      .ruge-label-content-s2 {
        overflow: hidden;
        display: table-cell;
        width: 100%;
        .ruge-label-btn-s {
          width: 190px;
          margin-right: 0px;
        }
      }
    }
    .ruge-label-content:last-child {
      border-bottom: 1px solid $borderColor;
    }
  }
}
</style>
